.terms {
	@extend .font-regular;
	@extend .text-textGrey;
	
	h1 {
		margin: 30px 0;
		@extend .font-bold;
		@extend .text-logoutGrey;
	}

	h3 {
		margin-bottom: 15px;
		@extend .font-medium;
		@extend .text-navy;
	}
	
	p {
		margin-bottom: 15px;
	}

	a {
		color: $navy;
	}

	.image1 {
		float: left;
		width: 50%;
		margin-right: 10px;
	}
}