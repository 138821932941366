.premium-lens-price-box {
    display: flex;
    flex-direction: row;
    margin-right: -15px;
    margin-left: -15px;
    /* border-bottom: 1px solid $navy; */
}

.h4-medium {
    font-size: 1.2em;
}

.navy-color {
    color: $navy !important;
}
.flex-row {
    width: 20%;
    text-align: center;
    font-size: 1em;
}
.dollar {
    margin-left: -6em;
    position: absolute;
    padding-top: 0.8em;
}

.dollar-non-toric {
    margin-left: -6em;
    position: absolute;
    padding-top: 0.8em;
}

.padding-zero {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 1em;
    margin-bottom: 1em;
}

.align-left {
    text-align: left;
}

.next-container {
    display: flex;
    flex-direction: row-reverse;
}