.exam-wrap {

}

.form-exam {
	@extend .font-regular;
	color: $textGrey;
	font-size: 12px;
	
	select,
	.form-control {
		border-radius: 0;
		box-shadow: none;
		@extend .font-regular;
		color: $textGrey;
		font-size: 12px;
	}
	
	.form-group-label {
		padding-right: 20px;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 25px;

		.control-label {
			text-align: right;
			/*align-self: center;*/
		}
	}

	.snellen {
		display: flex;
		/*justify-content: space-between;*/
		padding: 0;
		position: relative;

		select,
		.mark-wrap {
			margin-right: 20px;
			/*width: calc(50% - 60px);*/
			width: 100px;
		}
	}
	
	.mark-wrap,
	.mark-wrap-deg {
		position: relative;

		.six-pre {
			position: absolute;
			top:50%;
			transform:translateY(-50%);
			left:-20px;
		}

		.stripe-post {
			position: absolute;
			top:50%;
			transform:translateY(-50%);
			right:-15px;
		}

		.stripe-pre {
			position: absolute;
			top:50%;
			transform:translateY(-50%);
			left:-12px;
		}
	}


	.mark-wrap-deg {
	
		.deg {
			width: 80px;
			padding-left: 6px;
			padding-right: 6px;
		}
		
		.stripe-post {
			right: -37px;
		}

	}
}

.check-label {
	position: relative;

	.check-span {
		position: relative;
		top: 2px;
	}
}

.lens {
	width: 220px;
}

.lens-label {
	margin-top: 34px;
}

.visual-label {
	margin-top: 70px;
}

.form-group-label-top {
	margin-top: 40px;
}

.col-bin {
	padding-left: 60px;
}