@font-face {
    font-family: 'Gordita Heavy Italic';
    src: url('./fonts/gorditablackitalic-webfont.woff2') format('woff2'),
         url('./fonts/gorditablackitalic-webfont.woff') format('woff'),
         url('./fonts/gorditablackitalic-webfont.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
.font-heavy-italic {font-family: 'Gordita Heavy Italic';}

@font-face {
    font-family: 'Gordita Heavy';
    src: url('./fonts/gorditablack-webfont.woff2') format('woff2'),
         url('./fonts/gorditablack-webfont.woff') format('woff'),
         url('./fonts/gorditablack-webfont.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
.font-heavy {font-family: 'Gordita Heavy';}

@font-face {
    font-family: 'Gordita Bold Italic';
    src: url('./fonts/gorditabolditalic-webfont.woff2') format('woff2'),
         url('./fonts/gorditabolditalic-webfont.woff') format('woff'),
         url('./fonts/gorditabolditalic-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
.font-bold-italic {font-family: 'Gordita Bold Italic';}

@font-face {
    font-family: 'Gordita Bold';
    src: url('./fonts/gorditabold-webfont.woff2') format('woff2'),
         url('./fonts/gorditabold-webfont.woff') format('woff'),
         url('./fonts/gorditabold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
.font-bold {font-family: 'Gordita Bold';}

@font-face {
    font-family: 'Gordita Medium Italic';
    src: url('./fonts/gorditamediumitalic-webfont.woff2') format('woff2'),
         url('./fonts/gorditamediumitalic-webfont.woff') format('woff'),
         url('./fonts/gorditamediumitalic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
.font-medium-italic { font-family: 'Gordita Medium Italic'; }

@font-face {
    font-family: 'Gordita Medium';
    src: url('./fonts/gorditamedium-webfont.woff2') format('woff2'),
         url('./fonts/gorditamedium-webfont.woff') format('woff'),
         url('./fonts/gorditamedium-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
.font-medium { font-family: 'Gordita Medium'; }

@font-face {
    font-family: 'Gordita Regular Italic';
    src: url('./fonts/gorditaregularitalic-webfont.woff2') format('woff2'),
         url('./fonts/gorditaregularitalic-webfont.woff') format('woff'),
         url('./fonts/gorditaregularitalic-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
.font-regular-italic {font-family: 'Gordita Regular Italic';}

@font-face {
    font-family: 'Gordita Regular';
    src: url('./fonts/gorditaregular-webfont.woff2') format('woff2'),
         url('./fonts/gorditaregular-webfont.woff') format('woff'),
         url('./fonts/gorditaregular-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
.font-regular {font-family: 'Gordita Regular';}

@font-face {
    font-family: 'Gordita Light Italic';
    src: url('./fonts/gorditalightitalic-webfont.woff2') format('woff2'),
         url('./fonts/gorditalightitalic-webfont.woff') format('woff'),
         url('./fonts/gorditalightitalic-webfont.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
.font-light-italic {font-family: 'Gordita Light Italic';}


@font-face {
    font-family: 'Gordita Light';
    src: url('./fonts/gorditalight-webfont.woff2') format('woff2'),
         url('./fonts/gorditalight-webfont.woff') format('woff'),
         url('./fonts/gorditalight-webfont.ttf') format('truetype');
         font-weight: 200;
    font-style: normal;
}
.font-light {font-family: 'Gordita Light';}













