.modal-backdrop.am-fade {
	opacity: 0.5;
	transition: opacity 0.15s linear;
	&.ng-enter {
		opacity: 0;
		&.ng-enter-active {
			opacity: 0.5;
		}
	}
	&.ng-leave {
		opacity: 0.5;
		&.ng-leave-active {
			opacity: 0;
		}
	}
}

.modal-dialog {
	border: 0;

	.modal-content {
		border: 0;
		outline: none;
		border-radius: 0;

		.dismiss {
			position: absolute;
			z-index: 10;
			color: $white;
			font-size: 20px;
			right: 15px;
			top: -25px;
		}
	}
}

.modal-dialog.modal-center {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.my-modal-header {
	padding: 30px 30px;
}

.my-modal-footer {
	border-top: 1px solid $cyan;
	margin: 0;
	padding: 30px 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.btn {
		width: 180px;
	}
}
.my-modal-footer-right {
	justify-content: flex-end;
}

.my-modal-body {
	padding: 30px;
}

.table-details {
	width: 600px;
	/*outline: 1px dotted pink;*/
	td {
		/*outline: 1px dotted green;*/
		border: none !important;
		@extend .font-regular;
	}
	td:first-child {
		width: 250px;
		text-align: right;
		@extend .text-textGrey;
	}
	td:last-child {
		width: 350px;
		@extend .font-medium;
	}
}

.double-line-header {
	padding: 15px 0;
	height: 80px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.icon-wrap-success {
	height: 100px;
	img {
		height: 100%;
	}
}

.my-modal-footer-single-button {
	display: block;

	.btn {
		width: 220px;
		max-width: 220px;
	}
}

.limit-width {
	width: 450px;
	margin: 0 auto;
}

.limit-width-wide {
	width: 650px;
	margin: 0 auto;
}

.form-group-override-select {
	margin: 0 auto;
	width: 700px;
}

.override-modal {
	.form-control {
		border-radius: 0;
	}
	.additional-message {
		position: relative;

		.message {
			padding: 10px;
		}

		.count-remaining {
			position: absolute;
			font-size: 10px;
			bottom: 0px;
			right: 5px;
		}
	}

	.select-override,
	.message {
		font-size: 12px;
	}
}

.form-control:focus {
	border-color: #66afe9;
	border-color: $cyan;
	border-color: rgba(34, 190, 213, 0.6);
	outline: 0;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(34, 190, 213, 0.3);
}

.help-modal {
	.subtitle-line-break {
		margin: 10px 0;
	}

	.q-header-flex {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.h5 {
			max-width: 85%;
		}

		.q-icon-wrap {
			height: 35px;
			overflow: hidden;
			cursor: pointer;

			img {
				height: 100%;
			}
		}
	}
}

.q-icon-wrap {
	transition: transform 0.3s ease;
}

.rotate {
	transform: rotate(-45deg);
}

.q-body-wrap {
	display: none;
	padding-right: 60px;
}

.my-modal-body-help {
	max-width: 800px;
	margin: 0 auto;
}

.table-contact {
	margin-bottom: 0;

	td {
		border: 0 !important;
		@extand .font-regular;
		@extend .text-textGrey;
		padding-left: 0 !important;
		padding-top: 3px !important;
		padding-bottom: 3px !important;
	}
	td:first-child {
		width: 123px;
	}
}

.contact-name {
	/*margin-bottom: 0;*/
	margin-top: 20px;
	margin-bottom: 10px;
}

.morph-modal {
	color: $textGrey;
	.form-control {
		border-radius: 0;
		color: $textGrey;
		@extend .font-regular;
		font-size: 12px;

		option {
			color: $textGrey;
		}
	}
	.lens-wrap {
		margin-bottom: 5px;
		img {
			width: 100%;
		}
	}

	.lens-wrap-1 {
		margin-top: 20px;
		/*margin-bottom: 20px;*/
	}
}

@media (min-width: 992px) {
	.modal-lg-morph {
		width: 984px;
	}
}

.referral-radio-wrap {
	margin-top: 15px;

	.radio-inline {
		.marker {
			position: relative;
			left: -3px;
			top: 0px;
		}
	}

	.form-group {
		margin-bottom: 10px;
	}

	input[type="radio"] {
		margin-top: 1px;
	}

	.help-block {
		position: absolute;
		top: 10px;
	}
}

.notification-body {
	@extend .limit-width;
	@extend .font-regular;
	@extend .text-textGrey;

	ul {
		padding-left: 0;
	}

	li {
		margin-bottom: 10px;
	}

	ul > li > ul {
		padding-left: 40px;
	}
}

.hidden {
	display: none;
}

.flex-double-rows {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 1em !important;
	border-top-width: 0px !important;
}

.pharmacy-option {
	display: flex;
	flex-direction: column;
	
}

.pharmacy-title {
	padding-top: 0.1em;
}

.pharmacy-address {
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	font-size: 10pt;
}