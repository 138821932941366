$white: #ffffff;

/*$navy: #364FA2;*/
$navy: #0035d2;

$darkNavy: #2a307f;

$purple: #8b56a3;

$cyan: #22bed5;

$logoutGrey: #404141;

$blue: #2072bb;

/*Alternate colour for table display in search area*/
$paleBlue: #f2f6fb;

/*Navigation component on search page*/
$navGrey: #e7e9e9;

/*Page background colour*/
$pageBackGround: #ebecea;

$textGrey: #949599;

$lineBreak: #bccad2;

$cancelBorder: #bdbec1;

$danger: #a94442;

$dashboardBackground: #f0f2f5;
