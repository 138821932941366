.slideIn {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.slideIn.ng-enter,
.slideIn.ng-leave {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.slideIn.ng-enter {
    left: 100%;
}
.slideIn.ng-enter-active {
    left: 0;
}
.slideIn.ng-leave {
    left: 0;
}
.slideIn.ng-leave-active {
    left: 100%;
}

.slideOut {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.slideOut.ng-enter,
.slideOut.ng-leave {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.slideOut.ng-enter {
    left: -100%;
}
.slideOut.ng-enter-active {
    left: 0;
}
.slideOut.ng-leave {
    left: 0;
}
.slideOut.ng-leave-active {
    left: -100%;
}

.slidedown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.slidedown.ng-enter,
.slidedown.ng-leave {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.slidedown.ng-enter {
    top: -100%;
}
.slidedown.ng-enter-active {
    top: 0;
}
.slidedown.ng-leave {
    top: 0;
}
.slidedown.ng-leave-active {
    top: 100%;
}

.slideup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
}
.slideup.ng-enter,
.slideup.ng-leave {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.slideup.ng-enter {
    top: 100%;
}
.slideup.ng-enter-active {
    top: 0;
}
.slideup.ng-leave {
    top: 0;
}
.slideup.ng-leave-active {
    top: -100%;
}