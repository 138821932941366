.container-welcome {
	background: $white;
	min-height: 100%;
	padding: 0;
	border-radius: 0;

	.welcome-wrap {
		padding: 70px;
		padding-bottom: 30px;
		.h1 {
			font-size: 50px;
			margin-top: 0;
		}
		.welcome-question {
			margin-bottom: 20px;
			font-size: 16px;
		}
		select.form-control {
			width: 400px;
			border-radius: 18px;
			/* border-top-right-radius: 18px;
			border-top-left-radius: 18px; */
		}
	}
}

.container-welcome-footer {
	padding: 0;
	.footer-wrap {
		padding: 0 70px;

		ul {
			@extend .font-medium;
			@extend .text-navy;
			list-style-type: none;
			padding-left: 0;

			display:flex;
			flex-direction: row;
			justify-content: center;


			li {
				margin: 10px 80px;				
			}
		}
	}
}

iframe {
	/*border:3px solid #ddd;*/
	box-shadow: 0 0 3px 0px rgba(0,0,0,0.3);
	margin: 0 auto;
	display: block;
}