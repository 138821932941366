.container-refer {
	background-color: $white;
	padding: 10px;
	/*padding-bottom: 20px;*/
	margin-bottom: 20px;
	border-radius: 0;
}

.to-top {
	position:fixed;
	bottom: 4em;
	right: 4em;
	/* padding: 5px; */
	/* border: 1px solid #ccc; */
	/* background: red; */
	/* color: white; */
	font-weight: bold;
	cursor: pointer;
	/* display: none; */
  }

.container-dashboard {
	@extend .container-refer;
	background-color: $white;
	border-radius: 0;
}

.refer-nav {
	height: 100px;
	background-color: $pageBackGround;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.view-wrap {
	padding: 25px;
	padding-top: 0;
}

hr.subtitle-line-break {
	border-top-color: $lineBreak;
}

hr.cyan {
	border-top-color: $cyan;
	border-top-width: 1px;
}

.skip-wrap {
	button {
		width: 180px;
	}
	button:first-child {
		margin-right: 20px;
	}
}


.refer-nav-animation {
	height: 100px;
	background-color: $pageBackGround;
	/*background-color: #EBECEA;*/
	/*background-color: pink;*/
	display: flex;
	flex-direction: column;
	justify-content: center;
	/*align-items: center;*/

	.word-wrapper {
		/*background: lightblue;*/
		padding: 0 140px;
		padding: 0 100px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		p {margin-bottom: 0;}
		margin-bottom: 5px;
	}

	.animation-outter-wrapper {
		margin: 0 140px;
		position: relative;
		overflow: hidden;
		height: 6px;
		background: #D2D3D5;


		.prog {
			position: absolute;
			z-index: 2;
			width: 100%;
			height: auto;
		}

		.indicator {
			position: absolute;
			background: linear-gradient(90deg, $navy 0%, $navy 90%, $cyan 98%, $cyan 100%);
			height: 6px; 
			width: 100%;
			left:0;
			top:0;
			transition: all 1.5s ease;
			transform: translateX(-100%);
			/*border-radius: 50%;*/
			border-top-right-radius: 10px 10px;
			border-bottom-right-radius: 10px 10px;
		}

		.indicator.search {transform: translateX(-95%) !important; }
		.indicator.examination {transform: translateX(-50%);}
		.indicator.impact,
		.indicator.complete {
			transform: translateX(0%);
			border-top-right-radius: 0px 0px;
			border-bottom-right-radius: 0px 0px;	
		}
	}


}