.table-complete {
	th,
	td {
		border-top: 0 !important;
		vertical-align: middle !important;
	}

	th {
		color: $navy;
		@extend .font-bold;
		font-size: 16px;
	}

	td:nth-child(1) {
		/*background: pink;*/
		@extend .font-regular;
		color: $textGrey;
		font-size: 14px;
		/*width: 50%;*/
	}

	td:nth-child(2) {
		text-align: center;
		height: 60px;
		width: 160px;
		img {
			height: 100%;
			overflow: hidden;
		}
	}
	td:nth-child(3) {
		/*background: pink;*/
		width: 240px;
	}
	th:nth-child(2) {
		text-align: center;
		width: 200px;
	}

	.indent {
		padding-left: 30px;
		font-size: 12px;
		display: inline-block;
	}
}

.complete-flex-wrap {
	display:flex;
	justify-content: space-between;
	align-items: center;
}

.question-wrap {
	height: 44px;
	width: 44px;
	border: 2px solid gold;
	display: inline-block;
	border-radius: 50%;
	position: relative;

	span {
		@extend .font-bold;
		color: gold;
		font-size: 16px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.patient-summary-details {
	font-size: 10px;
}

.doc-flex-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	max-height: 400px;
	margin-bottom: 15px;
    overflow-y: auto;
    border: 1px solid lightgrey;
    padding: 5px;
	border-radius: 5px;
	
	&.doc-warning {
		background-color: rgba(255, 182, 193, 0.4);
	}

	.doc-card {
		border: 1px solid #ddd;
		width: 32.5%;
		margin-bottom: 8px;
		padding: 10px;
		cursor: pointer;
		border-radius: 6px;
		background-color: #fff;
		
		&:hover {
			box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
		}

		&.selected {
			background-color: $paleBlue;
		}

		&.doc-card-ghost {
			border:none;
			height: 0;
			padding: 0;
			margin: 0;
		}

		p {
			margin-bottom: 0;
		}
	}
}

.selected-doc-wrap {
	text-align: center;
	background-color: $paleBlue;
	border: 1px solid $lineBreak;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 6px;

	.selected-doc-name {
		@extend .font-bold-italic;
		@extend .text-navy;
	}
	.selected-doc-practice {
		@extend .mb-0;
		@extend .text-textGrey;
		@extend .font-regular;
	}
}

.doctor-filter-group {
	input[type=radio] {
		top: -2px;

	}
	
	.radio-inline {
		&:first-child {
			margin-left: 15px;
		}
	}
}